import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HotelIcon from '@material-ui/icons/Hotel';

import InfoText from '../../../UI/InfoText/InfoText';

const useStyles = makeStyles(theme => ({
  figures: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  infoText: {
    margin: "0 30px",
  }
}));

export default function SummaryFigures(props) {
  const classes = useStyles();
  const { hotels } = props;

  return (
    <Grid className={classes.figures}>
      {hotels && hotels > 0 ? <span className={classes.infoText}>
        <InfoText description="Hotels" text={hotels} icon={HotelIcon}/>
      </span> : ""}
    </Grid>
  );
};

SummaryFigures.propTypes = {
  hotels: PropTypes.number,
}
