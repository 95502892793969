import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles(theme => ({
  grid: {
    paddingBottom: 16,
    
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 8,
    }
  },
  description: {
    fontFamily: 'inherit',
    fontSize: 14,
    color: '#3F3D3A'
  },
  text: {
    fontFamily: 'Scandinavian New Black',
    fontSize: 32,
    textTransform: 'uppercase',
    lineHeight: '30px',
    
    [theme.breakpoints.only('xs')]: {
      fontSize: 24
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.support.dark,
    marginLeft: 8
  },
  final: {
    color: '#00870F'
  }
}));

const InfoText = props => {
  const classes = useStyles();

  // Props
  const { description, text, content, dense, final } = props;
  const Icon = props.icon;

  // Determine text class
  const textClass = final ? `${classes.text} ${classes.final}` : classes.text;

  // Check if content or text
  let data;
  if (content) {
    data = <Grid>{content}</Grid>;
  } else {
    // Text
    const textInfo = (
      <Typography className={textClass}>
        {text ? text : '-'}
      </Typography>
    );
    
    // Check if icon
    if (Icon) {
      data = (
        <Grid className={classes.container}>
          {textInfo}
          <Icon className={classes.icon} />
        </Grid>
      );
    } else {
      data = textInfo;
    }
  }

  return (
    <Grid className={!dense ? classes.grid : null}>
      <Typography className={classes.description}>
        {description}
      </Typography>
      {data}
    </Grid>
  );
};

InfoText.propTypes = {
  description: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array
  ]),
  icon: PropTypes.object,
  dense: PropTypes.bool
};

export default InfoText;
