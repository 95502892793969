import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

const StyledInput = withStyles({
  input: {
    textTransform: 'uppercase',

    '&::placeholder': {
      textTransform: 'initial'
    }
  }
})(Input);

const useStyles = makeStyles(theme => ({
  paper: {
    overflow: 'hidden',
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: 4
  },
  grid: {
    padding: "10px 16px",
  },
  gridLeft: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 44px)',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%'
    }
  },
  gridRight: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  datePicker: {
    marginLeft: 16,
    marginRight: 12
  },
  label: {
    color: '#3F3D3A',
    marginBottom: 4
  }
}));

export default function CustomInput(props) {
  const classes = useStyles();

  // Props
  const { value, date, onChange, label, placeholder } = props;
  const Icon = props.icon;

  // State
  const [fieldValue, setFieldValue] = useState('');
  const [sendValue, setSendValue] = useState(''); 

  // Refs
  const firstUpdate = useRef(true);
  const fieldCall = useRef(onChange);

  // Mount
  useEffect(() => {
    if (value && !date) {
      setFieldValue(value);
    } else if (!value && !date) {
      setFieldValue('');
    } else if (!value && date) {
      const today = new Date();
      setFieldValue(today);
      setSendValue(moment(today).format("YYYYMMDD"));
    }
  }, [value, date]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    fieldCall.current(sendValue);
  }, [firstUpdate, sendValue, fieldCall]);

  const handleDateChange = newDate => {
    setFieldValue(newDate);
    setSendValue(moment(newDate).format("YYYYMMDD"));
  }

  const handleChange = event => {
    const eventValue = event.target.value.toUpperCase();
    setFieldValue(eventValue);
    setSendValue(eventValue);
  };

  return (
    <Paper className={classes.paper}>
      {date ? 
      <Grid item xs={12} className={classes.datePicker}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="inline"
            format="D MMM YYYY"
            margin="normal"
            id="date-picker-inline"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
            }}
            value={fieldValue}
            onChange={handleDateChange}/>
          </MuiPickersUtilsProvider>
        </Grid>
      :
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={12} sm container className={classes.gridLeft}>
          <Typography className={classes.label}>
            {label}
          </Typography>
          <FormControl fullWidth>
            <StyledInput 
              type='text'
              className={classes.input}
              placeholder={placeholder}
              value={fieldValue}
              onChange={handleChange} 
              disableUnderline 
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.gridRight}>
          <Icon className={classes.icon} />
        </Grid>    
      </Grid>
      }
    </Paper>
  );
};

CustomInput.propTypes = {
  value: PropTypes.string,
  date: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired
};

