import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Rebooked from './containers/Rebooked/Rebooked';
import Layout from './hoc/Layout/Layout';
import * as routes from './Routes';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#006EE6",
    },
    secondary: {
      main: "#030E62",
    },
    support: {
      main: "#3F3D3A",
      light: "#737474"
    },
    success: {
      main: "#00A411",
      dark: "#00870F"
    },
    error: {
      main: "#FE0009",
      dark: "#DB060C"
    },
    header: {
      main: "#0017BF"
    }
  },
  typography: {
    fontFamily: 'inherit',
  },
});

export default function App() {

  const location = useLocation();
  const lastPath = useSelector(state => state.layout.lastPath);

  if (location && location.pathname === routes.PING) {
    return <div>OK</div>
  }

  const content = (
    <Layout path={location.pathname}>
      <Switch>
        <Route exact path={routes.REBOOKED} component={Rebooked} />
        <Redirect to={lastPath ? lastPath : "/rebooked"} />
      </Switch>
    </Layout>
  );

  return (
    <MuiThemeProvider theme={muiTheme}>
      {content}
    </MuiThemeProvider>
  );
}
