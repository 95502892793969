import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';

// Styles
const useStyles = makeStyles({
  contained: {
    fontFamily: 'Scandinavian New Black',
    minHeight: 44
  },
  icon: {
    minHeight: 44,
    marginRight: 16
  },
  iconLeft: {
    marginRight: 8
  },
  iconRight: {
    marginLeft: 8
  },
  text: {
    textTransform: 'initial',
    fontSize: 16,
    fontWeight: 'normal'
  },
  support: {
    color: 'white',
    backgroundColor: '#3F3D3A'
  },
  error: {
    backgroundColor: '#FE0009'
  }
});

export default function Button(props) {
  const classes = useStyles();

  // Props
  const { 
    id, action, text, variant, color, link, support, 
    disabled, fullWidth, icon, iconLeft, iconRight, error 
  } = props;

  // Icons
  const Icon = icon;
  const IconLeft = iconLeft;
  const IconRight = iconRight;

  // Action
  let buttonAction;
  if (action) {
    buttonAction = () => action();
  }

  // Change classes
  let className;
  if (variant) {
    className = classes.text;
  } else if (icon) {
    className = classes.icon;
  } else {
    className = classes.contained;
  }

  // Support classes
  if (support) {
    className = `${className} ${classes.support}`;
  } else if (error) {
    className = `${className} ${classes.error}`;
  }
  
  return (
    <MaterialButton
      id={id || null} 
      className={className} 
      variant={variant || "contained"} 
      component={Link} to={link || "#"}
      color={color || "primary"}
      onClick={buttonAction}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {icon && <Icon />}
      {iconLeft && <IconLeft className={classes.iconLeft} />}
      {text}
      {iconRight && <IconRight className={classes.iconRight} />}
    </MaterialButton>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  action: PropTypes.func,
  support: PropTypes.bool,
  error: PropTypes.bool
};

