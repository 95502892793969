import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

// TableCell Styled
const TableCellStyled = withStyles(theme => ({
  root: {
    fontFamily: 'Scandinavian New Regular',

    '&:first-child': {
      paddingLeft: 16
    },
    '&:last-child': {
      paddingRight: 16,
    },
    [theme.breakpoints.only('xs')]: {
      '&:first-child': {
        paddingLeft: 12
      },
      '&:last-child': {
        paddingRight: 12,
      }
    }
  },
  head: {
    backgroundColor: '#0017BF',
    color: 'white',
    padding: '4px 8px'
  },
  body: {
    padding: '24px 0 24px 8px'
  },
}))(TableCell);

// Tablecell Colspan
const TableCellColSpan = withStyles({
  root: {
    fontFamily: 'Scandinavian New Regular',
    '&:first-child': {
      paddingLeft: 8
    },
    '&:last-child': {
      paddingRight: 8,
    }
  },
  head: {
    backgroundColor: '#F4F3F3',
    color: 'black',
    padding: '4px 4px',
    textAlign: 'center'
  },
  body: {
   padding: '24px 4px',
   textAlign: 'center'
  }
})(TableCell);

// Styles
const useStyles = makeStyles({
  tight: {
    padding: '16px 8px'
  },
  border: {
    borderRight: '1px solid #C2C2C2'
  },
  colSpan: {
    textAlign: 'center'
  }
});

// TableCell
const AdvancedTableCell = props => {
  const classes = useStyles();

  // Props
  const { secondHead, colSpan, border, tight, children } = props;

  let content;
  if (colSpan) {
    content = <TableCellStyled className={classes.colSpan} colSpan={colSpan}>{children}</TableCellStyled>;
  } else if (secondHead) {
    content = <TableCellColSpan className={border ? classes.border : null}>{children}</TableCellColSpan>;
  } else if (tight) {
    content = <TableCellStyled className={classes.tight}>{children}</TableCellStyled>;
  } else {
    content = <TableCellStyled>{children}</TableCellStyled>;
  }
  return content;
}

AdvancedTableCell.propTypes = {
  secondHead: PropTypes.bool,
  colSpan: PropTypes.number,
  border: PropTypes.bool,
  tight: PropTypes.bool,
  order: PropTypes.string
};

export default AdvancedTableCell;
