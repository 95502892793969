import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

// Styles
const useStyles = makeStyles(theme => ({
  content: {
    color: theme.palette.support.light
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: 70,
    height: 70,
    marginBottom: 10,
  }
}));

const ErrorMessage = props => {
  const classes = useStyles();

  const { error } = props;

  let content;
  if (error) {
    content = (
      <Grid container className={classes.content}>
        <Grid item xs={12} className={classes.center}>
          <ErrorIcon className={classes.icon} />
        </Grid>
        <Grid item xs={12} className={classes.center}>
          {error}
        </Grid>
      </Grid>
    );
  } 

  return (
    <Fragment>
      {content}
    </Fragment>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
};

export default ErrorMessage;
