import * as types from '../../constants/ActionTypes';

const initialState = {
  title: "Disruption Dashboard",
  lastPath: null,
  location: null,
};

const reducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.SET_TITLE:
      return {
        ...state,
        title: action.title,
      }
    case types.SET_LAST_PATH:
      return {
        ...state,
        lastPath: action.lastPath
      }
    case types.SET_LOCATION:
      return {
        ...state,
        location: action.location
      }
    default:
      return state;
    }
};

export default reducer;

