import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = props => {
  const { color } = props;

  let circleStyle;
  if (color) {
    circleStyle = {
      color: color
    };
  }

  return <CircularProgress style={circleStyle} color="secondary" thickness={4} />;
};

Loading.propTypes = {
  color: PropTypes.string
};

export default Loading;
