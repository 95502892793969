import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  station: {
    textTransform: 'uppercase',
    fontFamily: 'Scandinavian New Black',
    fontSize: 24,
  },
  flightConnector: {
    position: "relative",
    margin: "0px 12px"
  },
  line: {
    position: "absolute",
    backgroundColor: '#000099',
    height: 3,
    top: 30,
    left: 3,
    right: 3,
  },
  leftDot: {
    position: "absolute",
    height: 12,
    width: 12,
    top: 25,
    backgroundColor: '#000099',
    borderRadius: "50%",
    display: "inline-block"
  },
  rightDot: {
    position: "absolute",
    height: 12,
    width: 12,
    top: 25,
    right: 0,
    backgroundColor: '#000099',
    borderRadius: "50%",
    display: "inline-block"
  },
  description: {
    fontSize: 14,
    color: theme.palette.support.light
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leftAlign: {
    display: 'flex',
  },
  onTime: {
    color: 'green',
  },
  delayed: {
    color: 'red',
  },
  strikeThrough: {
    textDecoration: 'line-through',
  },
  leftMargin: {
    marginLeft: 5
  },
  rightMargin: {
    marginRight: 5
  }
}));

export default function StationConnector(props) {
  const classes = useStyles();
  const { departureStation, arrivalStation, std, sta, etd, eta } = props;

  let stdStyle, etdStyle, staStyle, etaStyle;
  if (std && etd) {
    stdStyle = classes.strikeThrough;
    if (moment(std) < moment(etd)) {
      etdStyle = clsx(classes.delayed, classes.leftMargin);
    } else {
      etdStyle = clsx(classes.onTime, classes.leftMargin);
    }
  } else {
    stdStyle = classes.rightMargin;
  }

  if (sta && eta) {
    staStyle = classes.strikeThrough;
    if (moment(sta) < moment(eta)) {
      etaStyle = clsx(classes.delayed, classes.leftMargin);
    } else {
      etaStyle = clsx(classes.onTime, classes.leftMargin);
    }
  } else {
    staStyle = classes.leftMargin;
  }

  return ( 
    <Fragment>
      <Grid container>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12} className={classes.rightAlign}>
              <Typography className={classes.station}>{departureStation ? departureStation : '--'}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.rightAlign}>
              {std && <Typography className={stdStyle}>{moment(std).format('HH:mm')}</Typography>}
              {etd && <Typography className={etdStyle}>{moment(etd).format('HH:mm')}</Typography>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.flightConnector}>
            <span className={classes.leftDot}/>
            <span className={classes.line}/>
            <span className={classes.rightDot}/>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12} className={classes.leftAlign}>
              <Typography className={classes.station}>{arrivalStation ? arrivalStation : '--'}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.leftAlign}>
              {sta && <Typography className={staStyle}>{moment(sta).format('HH:mm')}</Typography>}
              {eta && <Typography className={etaStyle}>{moment(eta).format('HH:mm')}</Typography>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

StationConnector.propTypes = {
  departureStation: PropTypes.string,
  arrivalStation: PropTypes.string,
  std: PropTypes.string,
  sta: PropTypes.string,
  etd: PropTypes.string,
  eta: PropTypes.string,
  small: PropTypes.bool
}
