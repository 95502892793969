import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';

import TableCell from './TableCell';

// Table HeadRow
const TableHeadRow = withStyles({
  head: {
    height: 42
  }
})(TableRow);

// Arrow icon
const ArrowIcon = withStyles({
  root: {
    color: 'white !important'
  }
})(ArrowDownIcon);

// Table sort label
const SortLabel = withStyles({
  root: {
    '&$active': {
      color: 'white'
    }
  },
  active: {}
})(TableSortLabel);

export default function CustomTableHeader(props) {

  const { head, sortable, orderBy, order, sortBy } = props;

  return (
    <TableHead>
      <TableHeadRow>
        {head.map((cell, i) => {
          let label = cell;
          if (sortable && cell) {
            label = (
              <SortLabel active={orderBy === i} direction={order} onClick={() => sortBy(i)} IconComponent={ArrowIcon}>
                {cell}
              </SortLabel>
            );
          }
          return (
            <TableCell key={i}>
              {label}
            </TableCell>
          );
        })}
      </TableHeadRow>
    </TableHead>
  );
};

CustomTableHeader.propTypes = {
  head: PropTypes.array,
  secondHead: PropTypes.array,
  headColSpans: PropTypes.array,
  sortable: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.number,
  sortBy: PropTypes.func
};

