import ExcelJS from "exceljs";
import PropTypes from 'prop-types';

const columnWidths = Object.freeze({
  'Name': 40,
  'PNR': 10,
  'For itinerary': 70,
  'Hotel': 10,
});

const pageSetup = Object.freeze({
  paperSize: 9,// A4
  orientation: 'landscape',
  fitToPage: true,
  fitToHeight: 0,// fitToWidth
  showGridLines: true,
});

const generateExcel = (title, data) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(title, {
    views: [{ state: 'frozen', ySplit: 1 }],// first row frozen
    pageSetup,// printing settings
    headerFooter: { oddHeader: title },
  });
  sheet.columns = Object.keys(data[0]).map((key) => ({
    header: key,
    key,
    width: columnWidths[key],
  }));
  sheet.getRow(1).font = { bold: true };// bold header
  sheet.addRows(data);
  sheet.pageSetup.printArea = 'A1:' + sheet.lastRow.getCell(sheet.columnCount).address;
  return workbook;
};

const download = (doc, filename) => {
  doc.xlsx.writeBuffer().then((data: Blob) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename || "export.xls";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

const ExportXLS = (filename, title, data) => {
  const doc = generateExcel(title, data);
  download(doc, filename);
};

ExportXLS.propTypes = {
  filename: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
};

export default ExportXLS;
