import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Content from '../../hoc/Content/Content';
import Search from '../../components/Rebooked/Search/Search';
import FlightInfo from '../../components/Rebooked/FlightInfo/FlightInfo';
import PassengerList from '../../components/Rebooked/PassengerList/PassengerList';
import Loading from '../../components/UI/Loading/Loading.js';
import ErrorMessage from '../../components/UI/ErrorMessage/ErrorMessage.js';

import * as actions from '../../store/actions/index';

const useStyles = makeStyles({
  loading: {
    marginTop: 50,
    display: 'flex', 
    justifyContent: "center"
  },
  error: {
    marginTop: 30,
  },
});

export default function Rebooked() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const passengerList = useSelector(state => state.rebooked.passengerList);
  const flight = useSelector(state => state.rebooked.flight);

  const [loading, setLoading] = useState(false);

  const setTitle = useCallback(title => dispatch(actions.setTitle(title)), [dispatch]);

  useEffect(() => {
    setTitle("Rebooked passengers");
  }, [setTitle]);

  useEffect(() => {
    if (passengerList && flight) {
      setLoading(false);
    }
  }, [setLoading, passengerList, flight]);

  let content;
  if (passengerList && flight) {
    if (passengerList.data && flight.data) {
      content = (
        <Fragment>
          <Grid item xs={12}>
            <FlightInfo
              flight={flight.data}
              passengerList={passengerList.data}/>
          </Grid>
          <Grid item xs={12}>
            <PassengerList
              flight={flight.data}
              passengerList={passengerList.data}/>
          </Grid>
        </Fragment>
      );
    } else {
      content = (
        <Grid item xs={12} className={classes.error}>
          <ErrorMessage 
            error={passengerList.error}/>
        </Grid>
      );
    }
  } else if (loading) {
    content = (
      <Grid item xs={12} className={classes.loading}>
        <Loading />
      </Grid>
    );
  }
  
  return (
    <Fragment>
      <Content margins>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Search 
              setLoading={setLoading}/>
          </Grid>
          {content}
        </Grid>
      </Content>
    </Fragment>
  );
};
