import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Header from '../../components/Navigation/Header/Header';
import Drawer from '../../components/Navigation/Drawer/Drawer';

import * as actions from '../../store/actions/index';

const useStyles = makeStyles(theme => ({
  main: {
    height: '100%',
    minHeight: '100vh',
  },
  open: {
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.up('sm')]: {
      marginLeft: 240
    }
  },
  close: {
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.up('sm')]: {
      marginLeft: 65,
    }
  }
}));

export default function Layout(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { path, children } = props;

  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const sidebarOpen = JSON.parse(localStorage.getItem('showSidebar'));
    if (sidebarOpen) {
      setOpen(sidebarOpen);
    }
    return () => dispatch(actions.setLastPath(window.location.pathname));
  }, [dispatch]);

  const handleDrawerToggle = name => {
    if (name === 'open') {
      setOpen(!open);
      localStorage.setItem('showSidebar', JSON.stringify(!open));
    } else {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Fragment>
      <Header toggle={handleDrawerToggle} />
      <Drawer
        open={open} 
        path={path} 
        mobileOpen={mobileOpen} 
        toggle={handleDrawerToggle}
      />
      <main className={`${classes.main} ${open ? classes.open : classes.close}`}>
        {children}
      </main>
    </Fragment>
  );
};

Layout.propTypes = {
  path: PropTypes.string
};

