import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import HotelIcon from '@material-ui/icons/Hotel';
import Title from '../../UI/Title/Title';
import Table from '../../UI/Table/Table';
import ExportPDF from '../../Rebooked/Export/PDF/PDF';
import ExportXLS from '../../Rebooked/Export/XLS/XLS';
import Export from '../../Rebooked/Export/Export';

const useStyles = makeStyles({
  icon: {
    height: 18,
    marginRight: 3,
  },
  listSpan: {
    display: 'flex',
    verticalAlign: 'middle',
    padding: 1
  },
});

const getExportTitle = (flight) => {
  return `Rebooked ${flight.flightNumber} from ${flight.departureStation} to ${flight.arrivalStation} ${moment(flight.std).format('YYYY-MM-DD')}`
}

const getExportFilename = (flight) => {
  return `Rebooked ${flight.flightNumber} ${moment(flight.std).format('YYYY-MM-DD')}`
}

const getExportData = (passengerList) => (
  passengerList.map(passenger => (
    {
      "Name": getFullName(passenger.title, passenger.firstName, passenger.lastName),
      "PNR": passenger.pnr,
      "For itinerary": passenger.segments.flatMap(segment => segment.flatMap(flight => getFlightString(flight))).join('\r\n'),
      "Hotel": passenger.segments.flatMap(segment => segment.flatMap(flight => flight.hotel > 0 ? `${flight.hotel} ${flight.departureStation}` : "")).join('\r\n'),
    }
  ))
);

const getFlightString = (flight) => {
  return `${flight.flightNumber} / ${flight.departureStation} - ${flight.arrivalStation} / ${moment(flight.std).format('D MMM HH:mm')} - ${moment(flight.sta).format('D MMM HH:mm')}`
}

const getFullName = (title, firstName, lastName) => {
  return (lastName != null ? lastName : "") + 
         (firstName != null ? " " + firstName : "") + 
         (title != null ? " " + title : "");
}

export default function PassengerList(props) {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { passengerList, flight } = props;

  let tableHead;
  if (mobile) {
    tableHead = ['Passenger', 'For itinerary'];
  } else {
    tableHead = ['Name / PNR', 'For itinerary', 'Hotel'];
  }
  
  const tableBody = passengerList && passengerList.map(passenger => {
    const { pnr, title, firstName, lastName, segments } = passenger;

    const name = getFullName(title, firstName, lastName);

    const flightList = segments && segments.map((segment, i) => (
      segment.map((flight, j) => (
        <Grid key={j}>
          <span className={classes.listSpan}>
            ({i + 1}) {getFlightString(flight)}
          </span>
        </Grid>
      ))
    ));

    const hotels = segments && segments.map(segment => (
      segment.map((flight, i) => (
        flight.hotel > 0 &&
          (<Grid key={i}>
            <span className={classes.listSpan}>
              {flight.hotel} <HotelIcon className={classes.icon}/> {flight.departureStation}
            </span>
          </Grid>)
      ))
    ));

    const row = list => list.map((subList, i) => (
      <Grid key={i}>
        {subList.map((item, j) => (
          <Grid key={j} className={j === 0 ? classes.large : null}>
            {item || '-'}
          </Grid>
        ))}
      </Grid>
    ));

    if (mobile) {
      let numberOfFlights;
      if (flightList && flightList.length > 0) {
        numberOfFlights = flightList.length > 1 ? `${flightList.length} flights` : `${flightList.length} flight`;
      } else {
        numberOfFlights = "-";
      }
      return row([[name, pnr], [numberOfFlights]]);
    } else {
      return row([[name, pnr], [flightList], [hotels]]);
    }
  })

  let content;
  if (tableBody) {
    content = (
      <Fragment>
        <Title text={`${tableBody.length} passengers`} />
        <Table 
          tight
          head={tableHead} 
          body={tableBody} 
          empty="No passengers found" />
        {passengerList && flight &&
          <Export
            filename={getExportFilename(flight)}
            title={getExportTitle(flight)}
            data={getExportData(passengerList)}/>
        }
      </Fragment>
    );

  }
  

  return (
    <Fragment>
      {content}
    </Fragment>
  );
};

PassengerList.propTypes = {
  flight: PropTypes.object,
  passengerList: PropTypes.array
}
