import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import SummaryFigures from './SummaryFigures/SummaryFigures';
import FlightOverview from './FlightOverview/FlightOverview';
import Title from '../../UI/Title/Title';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 10,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

export default function FlightInfo(props) {
  const classes = useStyles();

  const { flight, passengerList } = props;

  let hotels = 0;
  passengerList && passengerList.map(passenger => (
    passenger.segments.map(segment => (
      segment.map(flight => {
        if (flight.hotel > 0) {
          hotels = hotels + 1;
        }
        return undefined;
      })
    ))
  ));

  let content;
  if (flight) {
    content = (
      <Fragment>
        <Title text='Summary'/>
        <Paper className={classes.paper}>
          <FlightOverview
            flight={flight}/>
          <SummaryFigures
            hotels={hotels}/>
        </Paper>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {content}
    </Fragment>
  );
};

FlightInfo.propTypes = {
  flight: PropTypes.object,
  passengerList: PropTypes.array
}
