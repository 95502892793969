// Layout
export const SET_TITLE = 'SET_TITLE';
export const SET_LAST_PATH = 'SET_LAST_PATH';
export const SET_LOCATION = 'SET_LOCATION';

// Rebooked Passengers
export const FETCH_REBOOKED_PASSENGERS_SUCCESS = 'FETCH_REBOOKED_PASSENGERS_SUCCESS';
export const FETCH_REBOOKED_PASSENGERS_ERROR = 'FETCH_REBOOKED_PASSENGERS_ERROR';
export const CLEAR_REBOOKED_PASSENGERS_DATA = 'CLEAR_REBOOKED_PASSENGERS_DATA';

// Flight
export const FETCH_FLIGHT_SUCCESS = 'FETCH_FLIGHT_SUCCESS';
export const FETCH_FLIGHT_ERROR = 'FETCH_FLIGHT_ERROR';
