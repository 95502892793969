import jsPDF from "jspdf";
import "jspdf-autotable";
import PropTypes from 'prop-types';

const generatePDF = (title, data) => {
  const doc = new jsPDF("landscape", "pt", "A4");
  const headers = Object.keys(data[0]);
  const body = data.map(d => Object.values(d));
  const content = {
    startY: 50,
    head: [headers],
    body: body,
  };
  doc.setFontSize(15);
  doc.text(title, 40, 40);
  doc.autoTable(content);
  return doc;
};

const download = (doc, filename) => {
  doc.save(filename || "export.pdf");
};

const ExportPDF = (filename, title, data) => {
  const doc = generatePDF(title, data);
  download(doc, filename);
};

ExportPDF.propTypes = {
  filename: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
};

export default ExportPDF;
