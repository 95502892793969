import * as types from '../../constants/ActionTypes';
import * as api from '../api';

export const fetchRebookedPassengersSuccess = data => {
  return {
    type: types.FETCH_REBOOKED_PASSENGERS_SUCCESS,
    data: data
  };
};

export const fetchRebookedPassengersError = error => {
  return {
    type: types.FETCH_REBOOKED_PASSENGERS_ERROR,
    error: error
  };
};

export const clearRebookedPassengersData = () => {
  return {
    type: types.CLEAR_REBOOKED_PASSENGERS_DATA
  };
};
// Fetch
export const fetchRebookedPassengers = (date, flightNumber, departureStation, arrivalStation) => {
  return api.get(`/rebooked?date=${date}&flightNumber=${flightNumber}&departureStation=${departureStation}&arrivalStation=${arrivalStation}`, fetchRebookedPassengersSuccess, fetchRebookedPassengersError);
};
