import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/5581c9a8-168b-45f0-abd4-d375da99bf9f',
    clientId: '92789ad8-8fb8-4b14-b34f-b8f64c006f7f',
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  }
};

// Authentication Parameters
const authenticationParameters = { }

// Options
const options = {
  loginType: LoginType.Redirect,
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
