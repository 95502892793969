import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';

import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles({
  paper: {
    overflowX: 'auto',
  },
  paperMargin: {
    overflowX: 'auto',
    marginBottom: 16
  }
});

export default function CustomTable(props) {
  const classes = useStyles();

  const { head, headColSpans, body, margin, error, update, tight, sortable } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(0);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, [firstUpdate, update, error]);

  const desc = (a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    return 0;
  }

  const handleSorting = (list) => {
    if (sortable) {
      return list.sort((a, b) => order === 'desc' ? desc(a, b) : -desc(a, b));
    } else {
      return list;
    }
  };

  const sortBy = index => {
    const isDesc = orderBy === index && order === 'desc';
    let newOrder;
    if (orderBy !== index) {
      newOrder = isDesc ? 'desc' : 'asc';
    } else {
      newOrder = isDesc ? 'asc' : 'desc';
    }
    setOrder(newOrder);
    setOrderBy(index);
  };

  const tableRows = handleSorting(body).map((row, i) => (
    <TableRow 
      key={i} 
      row={row}
      headColSpans={headColSpans}
      tight={tight}
    />
  ));

  let content;
  if (tableRows.length) {
    content = (
      <Paper className={margin ? classes.paperMargin : classes.paper}>
        <Table>
          <TableHeader 
            head={head} 
            order={order}
            orderBy={orderBy}
            sortBy={sortBy}
            sortable={sortable}
          />
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </Paper>
    );
  } else {
    content = "";
  }
  return content;
};

CustomTable.propTypes = {
  head: PropTypes.array,
  body: PropTypes.array,
  error: PropTypes.object,
  margin: PropTypes.bool,
  tight: PropTypes.bool,
  sortable: PropTypes.bool
};

