import React from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import './index.css';

import { BrowserRouter as Router } from 'react-router-dom';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './authProvider';

import layoutReducer from './store/reducers/Layout/Layout';
import rebookedReducer from './store/reducers/Rebooked/Rebooked';

const rootReducer = combineReducers({
  layout: layoutReducer,
  rebooked: rebookedReducer
});

const loggerMiddleware = createLogger();

let middleware = applyMiddleware(
  thunkMiddleware,
  loggerMiddleware
);

if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(
    thunkMiddleware
  );
}

export const store = createStore(
  rootReducer,
  middleware
);

if (process.env.NODE_ENV === 'production') {
  ReactDOM.render(
    <AzureAD provider={authProvider} forceLogin={true}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </AzureAD>,
    document.getElementById('root'),
  );
  serviceWorker.unregister();
} else {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('root'),
  );
  serviceWorker.unregister();
}
