import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const styles = {
  title: {
    fontFamily: 'Scandinavian New Black',
    textTransform: 'uppercase',
    marginBottom: 8
  }
};

export default function Title(props) {
  return (
    <Typography style={styles.title}>{props.text}</Typography>
  );
}

Title.propTypes = {
  text: PropTypes.string
};
