import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import DateIcon from '@material-ui/icons/Today';
import FlightIcon from '@material-ui/icons/Flight';
import DepartureIcon from '@material-ui/icons/FlightTakeoff';
import ArrivalIcon from '@material-ui/icons/FlightLand';

import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import * as actions from '../../../store/actions/index';

export default function Search(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();

  const fetchRebooked = useCallback((date, flightNumber, departureStation, arrivalStation) => dispatch(actions.fetchRebookedPassengers(date, flightNumber, departureStation, arrivalStation)), [dispatch]);
  const clearData = useCallback(() => dispatch(actions.clearRebookedPassengersData()), [dispatch]);

  const [inputs, setInputs] = useState(false);

  const inputsRef = useRef(inputs);

  useEffect(() => {
    clearData();
  }, [clearData]);

  useEffect(() => {
    inputsRef.current = inputs
  }, [inputs]);

  const handleInputChange = (name, input) => {
    setInputs({ ...inputsRef.current, [name]: input });
  };

  const fetchData = () => {
    clearData();
    setLoading(true);
    fetchRebooked(inputs.flightDate, inputs.flightNumber, inputs.departure, inputs.arrival);
  }

  let searchDisabled = true;
  if (inputs.flightDate && inputs.flightNumber && inputs.departure && inputs.arrival) {
    searchDisabled = false;
  }


  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Input
            icon={DepartureIcon}
            onChange={input => handleInputChange('departure', input)}
            placeholder="Departure station"/>
        </Grid>
        <Grid item xs={6}>
          <Input
            icon={ArrivalIcon}
            onChange={input => handleInputChange('arrival', input)}
            placeholder="Arrival station"/>
        </Grid>
        <Grid item xs={6}>
          <Input
            icon={FlightIcon}
            onChange={input => handleInputChange('flightNumber', input)}
            placeholder="Flight number"/>
        </Grid>
        <Grid item xs={6}>
          <Input
            date
            icon={DateIcon}
            onChange={input => handleInputChange('flightDate', input)}
            placeholder="Select date"/>
        </Grid>
        <Grid item xs={12}>
          <Button 
            fullWidth
            disabled={searchDisabled}
            action={fetchData}
            text="Search"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

Search.propTypes = {
  setLoading: PropTypes.func
}
