import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import PeopleIcon from '@material-ui/icons/People';
import CloseIcon from '@material-ui/icons/Close';

import * as routes from '../../../Routes';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    }
  },
  list: {
    paddingTop: 0
  },
  listItem: {
    padding: '12px 16px 12px 20px'
  },
  listItemIcon: {
    minWidth: 48
  },
  selected: {
    color: theme.palette.secondary.main
  },
  desktopToolbar: theme.mixins.toolbar,
  mobileToolbar: {
    padding: '4px 4px 4px 12px',
    textAlign: "right"
  },
}));

export default function CustomDrawer(props) {
  const theme = useTheme();
  const classes = useStyles();

  const { open, mobileOpen, toggle, path } = props;

  const menuItems = [
    {text:'Rebooked passengers', icon: PeopleIcon, link: routes.REBOOKED},
  ];

  const drawerList = (
    <List className={classes.list}>
      {menuItems.map(item => {
        const selected = path.includes(item.link) ? classes.selected : '';
        return (
          <ListItem 
            key={item.text} button
            className={classes.listItem} 
            component={Link} to={item.link}
            onClick={() => !desktop && toggle('mobileOpen')}
          >
            <ListItemIcon className={`${classes.listItemIcon} ${selected}`}>
              <item.icon />
            </ListItemIcon>
            <ListItemText className={selected} primary={item.text} />
          </ListItem>
        )
      })}
    </List>
  );
  
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  let content;
  if (desktop) {
    content = (
      <Drawer
        className={`${classes.drawer} ${open ? classes.drawerOpen : classes.drawerClose}`}
        classes={{
          paper: open ? classes.drawerOpen : classes.drawerClose,
        }}
        variant="permanent"
        open={open}
      >
        <Grid className={classes.desktopToolbar} />
        {drawerList}
      </Drawer>
    );
  } else {
    content = (
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={() => toggle('mobileOpen')}
        open={mobileOpen}>
        <Grid className={classes.mobileToolbar}>
          <IconButton aria-label="Menu" onClick={() => toggle('mobileOpen')}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {drawerList}
      </Drawer>
    );
  }

  return content;
}

Drawer.propTypes = {
  open: PropTypes.bool,
  mobileOpen: PropTypes.bool,
  toggle: PropTypes.func,
  path: PropTypes.string,
};

