import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FlightIcon from '@material-ui/icons/Flight';

import StationConnector from '../../../UI/StationConnector/StationConnector';

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    fontFamily: 'Scandinavian New Black',
    fontSize: 24,
    alignItems: 'center',
  },
  flightContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  flightIcon: {
    height: 40,
    width: 40,
    marginRight: 10,
    color: theme.palette.support.dark
  },
  flightStatus: {
    fontSize: 16,
    textTransform: 'capitalize',
    color: theme.palette.support.light
  },
}));

export default function FlightOverview(props) {
  const classes = useStyles();

  const { flight } = props;

  let content;
  if (flight) {
    content = (
      <Grid container>
        <Grid item xs={12} className={classes.flightContainer}>
          <FlightIcon className={classes.flightIcon}/>
          <div>
            <Typography className={classes.title}>{flight.flightNumber ? flight.flightNumber : '--'}</Typography>
            <Typography>{flight.std ? moment(flight.std).format('DD MMM YYYY') : '--'}</Typography>
            <Typography className={classes.flightStatus}>{flight.status}</Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <StationConnector
            departureStation={flight.departureStation}
            arrivalStation={flight.arrivalStation}
            etd={flight.etd}
            eta={flight.eta}
            std={flight.std}
            sta={flight.sta}/>
        </Grid>
      </Grid>
    );
  }

  return ( 
    <Fragment>
      {content}
    </Fragment>
  );
};

FlightOverview.propTypes = {
  flight: PropTypes.object.isRequired
}
