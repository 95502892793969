import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import DownloadIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import ExportPDF from './PDF/PDF'
import ExportXLS from './XLS/XLS'

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
  },
  download: {
    ariaLabel: 'download',
    bottom: theme.spacing(2),
    right: theme.spacing(2.5),
  },
  close: {
    ariaLabel: 'close',
    bottom: theme.spacing(2),
    right: theme.spacing(2.5),
  },
  pdf: {
    ariaLabel: 'pdf',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
  xls: {
    ariaLabel: 'xls',
    bottom: theme.spacing(19),
    right: theme.spacing(2),
  },
}));

const Export = (props) => {
  const { filename, title, data } = props;
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);

  const toggleButtons = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Fab
        size="medium"
        color={ collapsed ? "primary" : "default" }
        className={ `${ classes.fab } ${ collapsed ? classes.download : classes.close }` }
        onClick={ () => toggleButtons() }>
        { collapsed ? ( <DownloadIcon/> ) : ( <CloseIcon/> ) }
      </Fab>
      { !collapsed &&
        <>
          <Fab
            color="primary"
            className={ `${ classes.fab } ${ classes.pdf }` }
            onClick={ () => ExportPDF(filename, title, data) }>
            PDF
          </Fab>
          <Fab
            color="primary"
            className={ `${ classes.fab } ${ classes.xls }` }
            onClick={ () => ExportXLS(filename, title, data) }>
            XLS
          </Fab>
        </>
      }
    </>
  );
}

Export.propTypes = {
  filename: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
}

export default Export;
