import React from 'react';
import PropTypes from 'prop-types';

import TableRow from '@material-ui/core/TableRow';

import TableCell from './TableCell';

export default function CustomTableRow(props) {
  const { row, tight } = props;

  const rowCells = Object.values(row).map((cell, i) => (
    <TableCell key={i} tight={tight}>
      {cell}
    </TableCell>
  ));
  
  return (
    <TableRow>
      {rowCells}
    </TableRow>
  );
};

CustomTableRow.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  tight: PropTypes.bool
};
