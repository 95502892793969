import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Styles
const useStyles = makeStyles(theme => ({
  tabHeader: {
    padding: '144px 8px 64px',

    [theme.breakpoints.only('xs')]: {
      padding: '136px 8px 64px'
    }
  },
  content: {
    padding: '78px 14px 64px',

    [theme.breakpoints.only('xs')]: {
      padding: '72px 10px 64px'
    }
  }
}));

export default function Content(props) {
  const classes = useStyles();

  // Props
  const { tabHeader, margins, children } = props;

  let content;
  if (tabHeader) {
    content = <Grid className={classes.tabHeader}>{children}</Grid>;
  } else if (margins) {
    content = <Grid className={classes.content}>{children}</Grid>;
  } else {
    content = children;
  }
  return content;
};

Content.propTypes = {
  tabHeader: PropTypes.bool,
  margins: PropTypes.bool
};
