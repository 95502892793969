import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import logo from '../../../assets/images/sas-logo.svg';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#000099',
    zIndex: theme.zIndex.drawer + 1,

    [theme.breakpoints.only('xs')]: {
      zIndex: theme.zIndex.drawer - 1
    }
  },
  logo: {
    height: 40
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header(props) {
  const theme = useTheme();
  const classes = useStyles();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const title = useSelector(state => state.layout.title);

  const { toggle } = props;

  let toggleDrawer = () => toggle('mobileOpen');
  if (desktop) {
    toggleDrawer = () => toggle('open');
  }

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <IconButton edge="start" onClick={toggleDrawer} className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {desktop && <img src={`${logo}`} className={classes.logo} alt="logo" />}
      </Toolbar>
    </AppBar>
  );
}
