import axios from 'axios';

const domain = process.env.REACT_APP_BACKEND_URL || 'http://192.168.2.140:4501/api';

// Error
const handleError = (error, errorRequest) => {
  return dispatch => {
    if (error.response && error.response.status === 401) {
      dispatch(errorRequest(`${error.response.statusText}, please refresh the page`));
    } else if (error.response) {
      dispatch(errorRequest(error.response.data.message));
    } else {
      dispatch(errorRequest(error.message));
    }
  };
};

// GET
export const get = (path, successRequest, errorRequest) => {
  return async dispatch => await axios.get(domain + path)
    .then(response => dispatch(successRequest(response.data)))
    .catch(error => dispatch(handleError(error, errorRequest)));
};

// POST
export const post = (path, body, successRequest, errorRequest) => {
  return async dispatch => await axios.post(domain + path, body)
    .then(response => dispatch(successRequest(response.data)))
    .catch(error => dispatch(handleError(error, errorRequest)));
};

// PATCH
export const patch = (path, body, successRequest, errorRequest) => {
  return async dispatch => await axios.patch(domain + path, body)
    .then(response => dispatch(successRequest(response.data)))
    .catch(error => dispatch(handleError(error, errorRequest)));
};

// DELETE
export const del = (path, body, successRequest, errorRequest) => {
  return async dispatch => await axios.delete(domain + path, { data: body })
    .then(response => dispatch(successRequest(response.data)))
    .catch(error => dispatch(handleError(error, errorRequest)));
};

