import * as types from '../../constants/ActionTypes';

export const setTitle = title => {
  return {
    type: types.SET_TITLE,
    title: title 
  }
};

export const setLastPath = path => {
  return {
    type: types.SET_LAST_PATH,
    lastPath: path
  }
};

export const setLocation = location => {
  return {
    type: types.SET_LOCATION,
    location: location
  }
};

