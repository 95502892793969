import * as types from '../../constants/ActionTypes';

const initialState = {
  passengerList: undefined,
  flight: undefined,
};

const reducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.FETCH_REBOOKED_PASSENGERS_SUCCESS:
      return {
        ...state,
        passengerList: {
          ...state.passengerList,
          data: action.data && action.data.rebooked,
          error: null
        },
        flight: {
          ...state.flight,
          data: action.data && action.data.flight,
          error: null
        },
      }
    case types.FETCH_REBOOKED_PASSENGERS_ERROR:
      return {
        ...state,
        passengerList: {
          ...state.passengerList,
          data: null,
          error: action.error
        },
        flight: {
          ...state.flight,
          data: null,
          error: action.error
        },
      }
    case types.CLEAR_REBOOKED_PASSENGERS_DATA:
      return {
        ...state,
        passengerList: undefined,
        flight: undefined 
      }
    default:
      return state;
    }
};

export default reducer;

